import React from "react";
import ServicePage from "../../components/ServicePage";
import PageMeta from "../../components/PageMeta";

export default function PersonalCarePage() {
  return (
    <ServicePage>
      <PageMeta
        title="QCL Services - Personal care"
        path="services/personal-care"
      />
      <h1 className="top">Personal Care</h1>
      <p className="lead">
        QCL understand and actively support Government and Local Authorities’
        policies designed to help a person to continue living in his or her
        home.
      </p>
      <p>
        Unfortunately, either through disability or through the ageing process,
        a person may find it increasingly difficult to carry out everyday tasks.
        They may be physically or mentally unable to maintain standards they are
        used to – especially in the area of personal hygiene and care.
      </p>
      <p>
        To enable that person to continue to live at home for as long as
        possible, QCL can provide help and support that will promote
        independence and maintain dignity.
      </p>
      <p className="lead">
        Just because a person cannot cope with everyday tasks does not mean that
        he or she has to move away from their familiar surroundings into a new
        environment.
      </p>
      <p>
        We support a person’s independence by providing a range of personal care
        services that typically may include some or all of the following:-
      </p>
      <ul>
        <li>Getting in and out of bed</li>
        <li>Toileting</li>
        <li>Washing and bathing</li>
        <li>Dressing</li>
        <li>Food preparation</li>
      </ul>
      <p>
        Each person’s needs are unique, therefore QCL works closely with all
        concerned (Service User, next-of-kin, friends, Social Services, CCG’s,
        NHS etc.) to identify and assess a person’s specific requirements.
      </p>
      <p>
        Once these requirements have been assessed, a detailed care plan will be
        drawn up and agreed with all concerned.
      </p>
    </ServicePage>
  );
}
